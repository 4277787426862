import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Input, Link, Tab, Tabs, colors } from "@mui/material";
import { COLORS } from "../../../../../SupportingFiles/colors";
import "../NavBar.scss";
import ShareIcon from "@mui/icons-material/Share";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import RKTextField from "../../../../InputFields/RKTextField/RKTextField";
import CustomButton from "../../../../InputFields/CustomButton/CustomButton";

function LoginPopup({ open, setOpen }: { open: boolean; setOpen: Function }) {
  const [loginStep, setLoginStep] = useState<"1" | "2">("1");

  const [formData, setFormData] = useState<{
    mobile: { value: string; warning: boolean };
    otp: { value: string; warning: boolean };
  }>({
    mobile: { value: "", warning: false },
    otp: { value: "", warning: false },
  });

  const updateMasterState = (attrName: string, value: any) => {
    setFormData((prev) => ({ ...prev, [attrName]: { value: value } }));
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [open]);

  return (

    <>
      {open ? (
        <Box
          className="fullPagePopup"
          sx={{
            position: "fixed",
            background: COLORS.white,
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            paddingTop: "96px ",
            overflowY: "auto",
            zIndex: "999",
          }}
        >
          <Box className="">
            <Box
              sx={{
                height: "72px",
                position: "fixed",
                top: "0px",
                width: "100%",
                zIndex: "99",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                container
                columnSpacing={3}
                alignItems="center"
                width={"100%"}
              >
                <Grid xs={12} textAlign="right">
                  <Link
                    className="closePopup"
                    onClick={() => setOpen(false)}
                  ></Link>
                </Grid>
              </Grid>
            </Box>
            <Box className="content-sec">
              <Grid container columnSpacing={3} className="row content-sec">
                <Grid xs={12} textAlign={"center"} marginBottom={"16px"}>
                  <img src="./images/login.svg" alt="login-img" />
                </Grid>
              </Grid>

              {loginStep === "1" ? (
                <Grid
                  container
                  className="row"
                  columnSpacing={3}
                  justifyContent={"center"}
                >
                  <Grid xs={12} textAlign={"center"}>
                    <h3 style={{ fontSize: "28px", marginBottom: "8px" }}>
                      Welcome
                    </h3>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: 400,
                        marginBottom: "24px",
                        fontSize: "16px",
                        lineHeight: "18px",
                      }}
                    >
                      Login to your Account
                    </p>
                  </Grid>

                  <Grid xs={6}>
                    <Box maxWidth={"330px"} margin={"auto"}>
                      <RKTextField
                        class_name="inputField mb-6"
                        title={"Enter mobile number"}
                        value={formData.mobile.value}
                        attrName={"mobile"}
                        value_update={updateMasterState}
                        warn_status={formData.mobile.warning}
                        error_message={"Enter mobile number"}
                        validation_type="NUMBER"
                        max_length={10}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
                    <CustomButton
                      className="primaryBtn large"
                      text="Send OTP"
                      onClick={() => setLoginStep("2")}
                      fullWidth={false}
                      variant="contained"
                    />
                  </Grid>
                </Grid>
              ) : loginStep === "2" ? (
                <Grid
                  container
                  className="row"
                  columnSpacing={3}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  
                  <Grid xs={12} textAlign={"center"}>
                    <h3 style={{ fontSize: "28px", marginBottom: "8px" }}>
                      One Time Password
                    </h3>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: 400,
                        marginBottom: "24px",
                        fontSize: "16px",
                        lineHeight: "18px",
                      }}
                    >
                      sent successfully on{"  "}
                      <span style={{ fontWeight: 600, fontSize: "16px" }}>
                        {"  "}+91-{formData.mobile.value}
                      </span>
                      <Link
                        sx={{
                          fontSize: "13px",
                          padding: "2px 14px",
                          borderRadius: "8px",
                          color: COLORS.secondary,
                          border: `1px solid ${COLORS.secondary}`,
                          fontWeight: 500,
                          marginLeft: "12px",
                          display: "inline-block",
                          lineHeight: "18px",
                        }}
                        onClick={() => setLoginStep("1")}
                      >
                        Edit
                      </Link>
                    </p>
                  </Grid>
                  <Grid xs={8}>
                    <Box maxWidth={"330px"} margin={"auto"}>
                      <RKTextField
                        class_name="inputField mb-6"
                        title={"Enter OTP"}
                        value={formData.otp.value}
                        attrName={"otp"}
                        value_update={updateMasterState}
                        warn_status={formData.otp.warning}
                        error_message={"Enter OTP"}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
                    <CustomButton
                      className="primaryBtn large"
                      text="Verify OTP"
                      onClick={() => {}}
                      fullWidth={false}
                      variant="contained"
                    />
                  </Grid>
                  <Grid xs={12}>
                    <p style={{ textAlign: "center", fontSize: "16px" }}>
                      Didn’t receive?{" "}
                      <Link
                        sx={{
                          fontSize: "13px",
                          padding: "2px 14px",
                          borderRadius: "8px",
                          color: COLORS.secondary,
                          border: `1px solid ${COLORS.secondary}`,
                          fontWeight: 500,
                          marginLeft: "12px",
                          display: "inline-block",
                          lineHeight: "18px",
                        }}
                      >
                        Resend
                      </Link>
                    </p>
                  </Grid>
                </Grid>
              ) : null}
            </Box>
          </Box>
        </Box>
      ) : null}

    </>
  );
}

export default LoginPopup;
